import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Imports the form-input assets
import {
	InputComponent,
	InputPhoneComponent,
	InputPasswordComponent,
	InputSelectComponent,
	InputNumberComponent,
	InputUnitsComponent,
	InputFileComponent,
	InputImageComponent
} from './components';

// Pipes
import {
	CapitalizePipe,
	UserParamPipe,
	RequiredPipe,
	PossessivePipe,
	PhonePipe,
	ToLocalTimePipe,
	AddressPipe,
	ConvertUnitsPipe
} from './pipes';

// Import declarations
const FORM_INPUT_DECLARATIONS = [
	InputComponent,
	InputPhoneComponent,
	InputPasswordComponent,
	InputSelectComponent,
	InputNumberComponent,
	InputUnitsComponent,
	InputFileComponent,
	InputImageComponent
]

// Pipe declarations
const PIPE_DECLARATIONS = [
	CapitalizePipe,
	UserParamPipe,
	RequiredPipe,
	PossessivePipe,
	PhonePipe,
	ToLocalTimePipe,
	AddressPipe,
	ConvertUnitsPipe
]

@NgModule({
  imports: [
		CommonModule,
		FormsModule
  ],
  declarations: [
		...FORM_INPUT_DECLARATIONS,
		...PIPE_DECLARATIONS,
	],
	exports: [
		...FORM_INPUT_DECLARATIONS,
		...PIPE_DECLARATIONS
	],
	providers: [
		DecimalPipe,
		...PIPE_DECLARATIONS
	]
})
export class FormElementsModule { }
