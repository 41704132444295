import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
  
export class RouteStrategyService implements RouteReuseStrategy {


    public static handlers: {[key: string]: DetachedRouteHandle} = {};

    constructor() {}
  
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
      if (!route.data['shouldReuse']) {
        return null;
      }
     // console.log('Attach cached page for: ', route.data['key']);
      return RouteStrategyService.handlers[route.data['key']];
    }
  
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
      if (route.data['shouldReuse']) {
        RouteStrategyService.handlers[route.data['key']] = handle;
      }
    }
  
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
      return !!route.data['shouldReuse'];
    }
  
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
      return !!route.data['shouldReuse'] && !!RouteStrategyService.handlers[route.data['key']];
    }
  
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
      return !!future.data['shouldReuse'];
    }

    clear(){
        RouteStrategyService.handlers ={};        
    }
}