/* tslint:disable */
import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { formatDate } from '@angular/common';

import { Fleet } from '../models/fleet';
import { FleetInfo } from '../models/fleet-info';
import { ApiResponse } from '../models/api-response';
import { FleetProject } from "../models/fleet-project/fleet-project";
import { AuthenticationService } from "./authentication.service";


import {ProjectFile}from'../models/project-flie';
import{VdlFile}from '../models/vdl-file';
import { HttpRequestType } from "../models/httprequesttype";
import { J1939_Def } from'../models/definition-j1939';
import { Alert_Def } from '../models/definition-alert';
import { DINEX_Def } from '../models/definition-dinex';
import { SAE_Def } from '../models/definition-sae';
import { Operation_Def } from'../models/definition-operation';

/**
 * CATS Web API
 */
@Injectable()
class FleetService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
		private authService: AuthenticationService,
		@Inject(LOCALE_ID) private locale: string
  ) {
    super(config, http);
  }

  /**
   * @param body Fleet object that needs to be added to the System
   */
  updateFleetResponse(body: Fleet): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/fleet/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body Fleet object that needs to be added to the System
   */
  updateFleet(body: Fleet): Observable<void> {
    return this.updateFleetResponse(body).pipe(
      map(_r => _r.body)
    );
  }

	/**
   * @param body Fleet object that needs to be added to the System
   */
  createFleetResponse(body: Fleet): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fleet/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

	/**
   * @param body Fleet object that needs to be added to the System
   */
  createFleet(body: Fleet): Observable<void> {
    return this.createFleetResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * Multiple status values can be provided with comma separated strings
   * @param status Status values that need to be considered for filter
   * @return successful operation
   */
  findFleetsByStatusResponse(status: Array<'online' | 'offline'>): Observable<HttpResponse<Array<Fleet>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (status || []).forEach((val, index) => {if (val != null) __params = __params.append('status', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fleet/findByFleetStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<Fleet> = null;
        _body = _resp.body as Array<Fleet>;
        return _resp.clone({body: _body}) as HttpResponse<Array<Fleet>>;
      })
    );
  }

  /**
   * Multiple status values can be provided with comma separated strings
   * @param status Status values that need to be considered for filter
   * @return successful operation
   */
  findFleetsByStatus(status: Array<'online' | 'offline'>): Observable<Array<Fleet>> {
    return this.findFleetsByStatusResponse(status).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * Returns a single fleet (Req. 2-2, 7-0)
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
  getFleetByIdResponse(fleetId: number): Observable<HttpResponse<FleetInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fleet/${fleetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: FleetInfo = null;
        _body = _resp.body as FleetInfo;
        return _resp.clone({body: _body}) as HttpResponse<FleetInfo>;
      })
    );
  }



  getFleetConeectionByIdResponse(fleetId: number): Observable<HttpResponse<FleetInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/admin/config/connectionhistory/${fleetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: FleetInfo = null;
        _body = _resp.body as FleetInfo;
        return _resp.clone({body: _body}) as HttpResponse<FleetInfo>;
      })
    );
  }



  getVehicleSnapshotPackageByIdResponse(vehicleId: number): Observable<HttpResponse<FleetInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/admin/config/vehiclepkgdata/${vehicleId}/10`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: FleetInfo = null;
        _body = _resp.body as FleetInfo;
        return _resp.clone({body: _body}) as HttpResponse<FleetInfo>;
      })
    );
  }
  /**
   * Returns a single fleet (Req. 2-2, 7-0)
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
  getFleetById(fleetId: number): Observable<FleetInfo> {
    return this.getFleetByIdResponse(fleetId).pipe(
      map(_r => _r.body)
    );
  }


  getFleetConnectionById(fleetId: number): Observable<FleetInfo> {
    return this.getFleetConeectionByIdResponse(fleetId).pipe(
      map(_r => _r.body)
    );
  }


  getVehicleSnapshotPackageById(vehicleId: number): Observable<FleetInfo> {
    return this.getVehicleSnapshotPackageByIdResponse(vehicleId).pipe(
      map(_r => _r.body)
    );
  }

	/** Retrieves the fleet info by ID */
	getFleetInfoById (fleetId: number): Observable <FleetInfo> {
		return this.getFleetInfoByIdResponse(fleetId).pipe(
      map(_r => _r.body)
    );
	}

	/**
   * Returns the fleet's fleet-info (Req. 2-2, 7-0)
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
	getFleetInfoByIdResponse (fleetId: number): Observable <HttpResponse <FleetInfo>> {
		let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fleet/info/${fleetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: FleetInfo = null;
        _body = _resp.body as FleetInfo;
        return _resp.clone({body: _body}) as HttpResponse<FleetInfo>;
      })
    );
	}

  /**
   * @param params The `FleetService.UpdateFleetWithFormParams` containing the following parameters:
   *
   * - `fleetId`: ID of fleet that needs to be updated
   *
   * - `status`: Updated status of the fleet
   *
   * - `name`: Updated name of the fleet
   */
  updateFleetWithFormResponse(params: FleetService.UpdateFleetWithFormParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = params;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fleet/${params.fleetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

	 /**
   * @param params The `FleetService.UpdateFleetWithFormParams` containing the following parameters:
   *
   * - `fleetId`: ID of fleet that needs to be updated
   *
   * - `status`: Updated status of the fleet
   *
   * - `name`: Updated name of the fleet
   */
  updateFleetInfoWithFormResponse(params: FleetService.UpdateFleetWithFormParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = params.fleetInfo;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fleet/info/${params.fleetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `FleetService.UpdateFleetWithFormParams` containing the following parameters:
   *
   * - `fleetId`: ID of fleet that needs to be updated
   *
   * - `status`: Updated status of the fleet
   *
   * - `name`: Updated name of the fleet
   */
  updateFleetInfoWithForm(params: FleetService.UpdateFleetWithFormParams): Observable<void> {
    return this.updateFleetInfoWithFormResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FleetService.DeleteFleetParams` containing the following parameters:
   *
   * - `fleetId`: Fleet id to delete
   *
   * - `api_key`:
   */
  deleteFleetResponse(params: FleetService.DeleteFleetParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null) __headers = __headers.set('api_key', params.apiKey.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/fleet/${params.fleetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `FleetService.DeleteFleetParams` containing the following parameters:
   *
   * - `fleetId`: Fleet id to delete
   *
   * - `api_key`:
   */
  deleteFleet(params: FleetService.DeleteFleetParams): Observable<void> {
    return this.deleteFleetResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FleetService.UploadFileParams` containing the following parameters:
   *
   * - `fleetId`: ID of fleet to update
   *
   * - `file`: file to upload
   *
   * - `additionalMetadata`: Additional data to pass to server
   *
   * @return successful operation
   */
  uploadFileResponse(params: FleetService.UploadFileParams): Observable<HttpResponse<ApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;

    __formData.append('file', params.file);
    __formData.append('additionalMetadata', params.additionalMetadata);
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fleet/${params.fleetId}/uploadImage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ApiResponse = null;
        _body = _resp.body as ApiResponse;
        return _resp.clone({body: _body}) as HttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * @param params The `FleetService.UploadFileParams` containing the following parameters:
   *
   * - `fleetId`: ID of fleet to update
   *
   * - `file`: file to upload
   *
   * - `additionalMetadata`: Additional data to pass to server
   *
   * @return successful operation
   */
  uploadFile(params: FleetService.UploadFileParams): Observable<ApiResponse> {
    return this.uploadFileResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  getFleetListByUser(username:string):Observable<any>{
    return  this.http.get(
      this.rootUrl +`/admin/config/allfleetlist/${username}`
    );
  }


  deleteVdlFile(fleetId: number,configId:number){

    let __params = this.newParams().set('config_id',configId.toString());
    let option = {params:__params};

    return this.http.delete<ApiResponse>(this.rootUrl + `/admin/config/vdlconfig/${fleetId}`,option)
    .pipe(
      map(resp=>{
          return resp.status === 200? true:false;
      })
    );
  }

  updateNewVdlFile(params:VdlFile){

    var formData: any = new FormData();
    formData.set('version',params.version);
    formData.set('user_name', params.user_name);
    formData.set('vdlfile', params.vdl_file);

    return this.http.post<ApiResponse>(this.rootUrl + `/admin/config/vdlconfig/${params.fleet_id}`, formData)
    .pipe(
        map(
          resp=>{
            return resp;
          }
        )
    )
  }

  deleteVdlApp(fleetId: number,fileId:number){

    let __params = this.newParams().set('app_file_id',fileId.toString());
    let option = {params:__params};

    return this.http.delete<ApiResponse>(this.rootUrl + `/admin/vdl/app/file/${fleetId}`,option)
    .pipe(
      map(resp=>{
          return resp.status === 200? true:false;
      })
    );
   
  }
  updateNewVdlApp(params:VdlFile){

    var formData: any = new FormData();
    formData.set('version',params.version);
    formData.set('user_name', params.user_name);
    formData.set('appfile', params.vdl_file);

    return this.http.post<ApiResponse>(this.rootUrl + `/admin/vdl/app/file/${params.fleet_id}`, formData)
    .pipe(
        map(
          resp=>{
            return resp;
          }
        )
    )
  }

  deleteDataDefinitionByType(fleetId: number,configId:number,itemType:string){

    let __params = this.newParams().set('config_id',configId.toString());
    let option = {params:__params};

    return this.http.delete<ApiResponse>(this.rootUrl + `/admin/config/data/${fleetId}/${itemType}`,option)
    .pipe(
      map(resp=>{
          return resp.status === 200? true:false;
      })
    );
  }

  updateNewAlertDefinition(params:Alert_Def){

    var formData: any = new FormData();
    formData.set('index',params.alert_index);
    formData.set('item_desc', params.alert_desc);
    formData.set('output', params.dinex_output);
    formData.set('start_bit', params.length);
    formData.set('length', params.start_bit);
    formData.set('remark', params.remark);

    return this.http.post<ApiResponse>(this.rootUrl + `/admin/config/data/${params.select_fleet}/alert`, formData)
    .pipe(
        map(
          resp=>{
            return resp;
          }
        )
    )

  }

  updateNewJ1939Definition(params:J1939_Def){

    var formData: any = new FormData();
    formData.set('can_id',params.can_ID);
    formData.set('item_desc', params.desc);
    formData.set('offset', params.offset);
    formData.set('resolution', params.resolution);
    formData.set('spn', params.spn);
    formData.set('start_bit', params.startBit);
    formData.set('unit', params.unit);
    formData.set('length', params.length);
    formData.set('remark', params.remark);

    return this.http.post<ApiResponse>(this.rootUrl + `/admin/config/data/${params.select_fleet}/j1939`, formData)
    .pipe(
        map(
          resp=>{
            return resp;
          }
        )
    )

  }

  updateNewDinexDefinition(params:DINEX_Def){

    var formData: any = new FormData();
    formData.set('can_id',params.can_id);
    formData.set('channel', params.channel_name);
    formData.set('item_desc', params.desc);
    formData.set('length', params.length);
    formData.set('offset', params.offset);
    formData.set('remark', params.remark);
    formData.set('resolution', params.resolution);
    formData.set('start_bit', params.start_bit);
    formData.set('unit', params.unit);

    return this.http.post<ApiResponse>(this.rootUrl + `/admin/config/data/${params.select_fleet}/dinex`, formData)
    .pipe(
        map(
          resp=>{
            return resp;
          }
        )
    )

  }

  updateNewSaeDefinition(params:SAE_Def){

    var formData: any = new FormData();
    formData.set('item_desc',params.desc);
    formData.set('length', params.length);
    formData.set('offset', params.offset);
    formData.set('pgn', params.pgn);
    formData.set('remark', params.remark);
    formData.set('resolution', params.resolution);
    formData.set('spn', params.spn);
    formData.set('start_bit', params.start_bit);
    formData.set('unit', params.unit);

    return this.http.post<ApiResponse>(this.rootUrl + `/admin/config/data/${params.select_fleet}/sae`, formData)
    .pipe(
        map(
          resp=>{
            return resp;
          }
        )
    )

  }

  updateNewOperationDefinition(params:Operation_Def){

    var formData: any = new FormData();
    formData.set('alert_type',params.alert_type);
    formData.set('item_desc', params.desc);
    formData.set('index', params.index);
    formData.set('output', params.output);
    formData.set('remark', params.remark);

    return this.http.post<ApiResponse>(this.rootUrl + `/admin/config/data/${params.select_fleet}/operation`, formData)
    .pipe(
        map(
          resp=>{
            return resp;
          }
        )
    )

  }

  deletePrjFile(fleetId: number,configId:number){

    let __params = this.newParams().set('config_id',configId.toString());
    let option = {params:__params};

    return this.http.delete<ApiResponse>(this.rootUrl + `/fleet/prjfile/${fleetId}`,option)
    .pipe(
      map(resp=>{
          return resp.status === 200? true:false;
      })
    );
  }

  updateNewPrjFile(params:ProjectFile){

    var formData: any = new FormData();
    formData.set('version',params.version);
    formData.set('user_name', params.user_name);
    formData.set('prjFile', params.project_file);

    return this.http.post<ApiResponse>(this.rootUrl + `/fleet/prjfile/${params.fleet_id}`, formData)
    .pipe(
        map(
          resp=>{
            return resp;
          }
        )
    )

  }

	/**
   * @param params The `FleetProject`
   *
   * @return successful operation
   */
  uploadProjFileResponse(params: FleetProject): Observable<HttpResponse<ApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;

		__formData.append ('version', params.version);
		__formData.append ('status', params.status);
		__formData.append ('prjFile', params.prjFile);

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/fleet/prjfile/${params.fleetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ApiResponse = null;
        _body = _resp.body as ApiResponse;
        return _resp.clone({body: _body}) as HttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * @param params The `FleetService.UploadFileParams` containing the following parameters:
   *
   * - `fleetId`: ID of fleet to update
   *
   * - `file`: file to upload
   *
   * - `additionalMetadata`: Additional data to pass to server
   *
   * @return successful operation
   */
  uploadProjFile(params: FleetProject): Observable<ApiResponse> {
    return this.uploadProjFileResponse(params).pipe(
      map(_r => _r.body)
    );
  }


      /**
   * Returns a single fleet (Req. 2-2, 7-0)
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
  getFleetDataByIDAndItemTypeResponse(fleetId: number,itemType:string): Observable<HttpResponse<J1939_Def>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/admin/config/data/${fleetId}/${itemType}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body:J1939_Def = null;
        _body = _resp.body as J1939_Def;
        return _resp.clone({body: _body}) as HttpResponse<J1939_Def>;
      })
    );
  }

  /**
   * Returns a single fleet (Req. 2-2, 7-0)
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
  getFleetDataByIDAndItemType(fleetId: number,itemType:string): Observable<J1939_Def> {
    return this.getFleetDataByIDAndItemTypeResponse(fleetId,itemType).pipe(
      map(_r => _r.body)
    );
  }



    /**
   * Returns Prj info by fleet ID
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
  getFleetPrjByIDResponse(fleetId: number): Observable<HttpResponse<ProjectFile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fleet/prjfile/${fleetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body:ProjectFile = null;
        _body = _resp.body as ProjectFile;
        return _resp.clone({body: _body}) as HttpResponse<ProjectFile>;
      })
    );
  }

  /**
   * Returns Prj info by fleet ID (Req. 2-2, 7-0)
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
  getFleetPrjByID(fleetId: number): Observable<ProjectFile> {
    return this.getFleetPrjByIDResponse(fleetId).pipe(
      map(_r => _r.body)
    );
  }


      /**
   * Returns Vdl info by fleet ID
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
  getFleetVdlByIDResponse(fleetId: number): Observable<HttpResponse<VdlFile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/admin/config/vdlconfig/${fleetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body:VdlFile = null;
        _body = _resp.body as VdlFile;
        return _resp.clone({body: _body}) as HttpResponse<VdlFile>;
      })
    );
  }




  /**
   * Returns  Vdl info by fleet ID
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
  getFleetVdlAppByID(fleetId: number): Observable<VdlFile> {
    return this.getFleetVdlAppByIDResponse(fleetId).pipe(
      map(_r => _r.body)
    );
  }

      /**
   * Returns Vdl info by fleet ID
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
  getFleetVdlAppByIDResponse(fleetId: number): Observable<HttpResponse<VdlFile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/admin/vdl/app/file/${fleetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body:VdlFile = null;
        _body = _resp.body as VdlFile;
        return _resp.clone({body: _body}) as HttpResponse<VdlFile>;
      })
    );
  }




  /**
   * Returns  Vdl info by fleet ID
   * @param fleetId Pass in fleet id
   * @return successful operation
   */
  getFleetVdlByID(fleetId: number): Observable<VdlFile> {
    return this.getFleetVdlByIDResponse(fleetId).pipe(
      map(_r => _r.body)
    );
  }
  /** Transforms a date object into a UTC converted SQL-style timestamp */
	public transformToUTC(date: Date) {
		return formatDate (date, 'yyyy-MM-dd HH:mm:ss', this.locale, 'UTC');
	}

  /** Overrides the newParams function to include the user's API token */
	public newParams () {
		return super.newParams().set (
			'cats_api_key', this.authService.getSessionToken()
		).set ('username', this.authService.getUsername());
	}

	/** Formats a fleet info object to be sent into the API call */
	public prepareFleetInfoObject (fleetInfo: FleetInfo) {
		let changedFleet: FleetInfo = fleetInfo;
		const phoneTransformExpression: any = /\D/g;

		changedFleet.fleet_contact = ('' + changedFleet.fleet_contact).replace(phoneTransformExpression, '');
		changedFleet['online_status'] = 'online';

		// Necessary because number fields treat 0 as an empty value.
		if (!changedFleet.time_offset) {
				changedFleet.time_offset = 0;
		}
		if (!changedFleet.time_zone) {
			changedFleet.time_zone = 0;
		}

		return changedFleet;
  }

    /** Formats a prj  object to be sent into the API call */
	public preparePrjObject (prjFile: ProjectFile, requestType: HttpRequestType) {
		let changedPrj: ProjectFile = prjFile;
		return changedPrj;
  }
}

module FleetService {

  /**
   * Parameters for updateFleetWithForm
   */
  export interface UpdateFleetWithFormParams {

    /**
     * ID of fleet that needs to be updated
     */
    fleetId: number;

    /**
     * Updated status of the fleet
     */
    status?: string;

    /**
     * Updated name of the fleet
     */
    name?: string;

		/** Form data tied to this fleet update */
		fleetInfo?: FleetInfo;
  }

  /**
   * Parameters for deleteFleet
   */
  export interface DeleteFleetParams {

    /**
     * Fleet id to delete
     */
    fleetId: number;
    apiKey?: string;
  }

  /**
   * Parameters for uploadFile
   */
  export interface UploadFileParams {

    /**
     * ID of fleet to update
     */
    fleetId: number;

    /**
     * file to upload
     */
    file?: Blob;

    /**
     * Additional data to pass to server
     */
    additionalMetadata?: string;
  }
}

export { FleetService }
