export const navigation = [
  {
    name: 'Home',
    url: '/home',
    icon: 'icon-home',
    badge: {
      variant: 'info',
      text: 'Start'
    }
  },
  {
    name: 'Fleet',
    url: '/fleet',
    icon: 'fa fa-bus'
  },
  {
    name: 'Alert',
    url: '/alert-list',
    icon: 'icon-bell'
  },
  {
    name: 'Analysis',
    url: '/analysis',
    icon: 'fa fa-line-chart'
  },
  {
    name: 'Preventive Maint',
    url: '/pmn/1',
    icon: 'icon-umbrella'
  },
  {
    name: 'Data Log',
    url: '/datalog',
    icon: 'icon-event',
    children: [
      {
        name: 'Maintenance',
        url: '/datalog/maintenance',
        badge: {
          variant: 'secondary',
          text: 'LOG'
        }
      },
      {
        name: 'Daily Routine',
        url: '/datalog/routine',
        badge: {
          variant: 'secondary',
          text: 'RPT'
        }
      }
    ]
  },
  {
    name: 'Vehicle Reports',
    url: '/vehicleReports',
    icon: 'icon-graph'
  },
  {
    name: 'Settings',
    url: '/admin/usermanagement',
    icon: 'fa fa-cogs',
    admin: 'false',

    children: [
      {
        name: 'Alert Subscription',
        url: '/alert-subscription'
      }
    ]
  },
  {
    name: 'Admin Settings',
    url: '/admin/usermanagement',
    icon: 'fa fa-cogs',
    admin: 'true',

    children: [
      {
        // User Management
        name: 'User Management',
        url: '/admin/usermanagement',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        // Notification Setting
        name: 'Notification Setting',
        url: '/admin/pmnsetting',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        // New User
        name: 'Create New User',
        url: '/admin/newuser',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        name: 'Connection',
        url: '/admin/connection',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        name: 'VDL Data Define',
        url: '/admin/catsDataDefine',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        name: 'VDL File Setting',
        url: '/admin/catsFileSetting',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      },
      {
        name: 'Division Presumption',
        url: '/admin/divisionPresumption',
        badge: {
          variant: 'secondary',
          text: 'ADMIN'
        }
      }
    ]
  }
];
