import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { DivisionInfoDto } from 'app/api/models/division/division-info-dto';

@Injectable()
class DivisionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
  ) {
    super(config, http);
  }

  getAllDivisionInfo() {
    return this.http.get<DivisionInfoDto[]>(`${this.rootUrl}/divisions`);
  }

}


export { DivisionService }