import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'toLocalTime'
})
export class ToLocalTimePipe implements PipeTransform {
	/** Transforms any UTC time string to one shown in the browser's local time */
	transform(value: string, args?: any): any {
		const expressionTest: any = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/;
		// Return the value itself if it is not a valid date
		if (!expressionTest.test(value)) {
			return value;
		}
		// Otherwise, construct the time in localtime
		const timeTransform = value.replace(/\s/, 'T') + 'Z';
		const localTime = new Date(timeTransform);
		// Format: yyyy-MM-DD hh-mm-ss
		return localTime.getFullYear() + '-' + this.doubleFormat(localTime.getMonth() + 1) + '-' + this.doubleFormat(localTime.getDate())
			+ ' ' + this.doubleFormat(localTime.getHours()) + ':' + this.doubleFormat(localTime.getMinutes()) + ':' + this.doubleFormat(localTime.getSeconds());
	}

	/** Enforces double digit formatting
	 * 		@param {number} value - The value being transformed */
	public doubleFormat(value: number) {
		return (value < 10 ? '0' + value : '' + value);
	}

}
