import { Injectable } from '@angular/core';
import { Subscription, timer} from 'rxjs';
import { UserService } from 'app/api/services/user.service';

@Injectable()
export class ActivityLogService  {

    constructor(
        private userService: UserService
    ) {}

    private sendOnlineLogSub: Subscription = null;
    private SendOnlineLogTimeInterval: number =  5 * 60 * 1000;

    // Send online status to user activity log to ananlysis user logging time
    public startSendOnlineLog(userName: string) {

        if (this.sendOnlineLogSub == null) {
            this.sendOnlineLogSub = timer(this.SendOnlineLogTimeInterval, this.SendOnlineLogTimeInterval)
            .subscribe(() => {
                this.userService.postUserOnlineActivity(userName)
                    .subscribe();
            });
        }
    }

    public endSendOnlineLog() {
          if (this.sendOnlineLogSub) {
            this.sendOnlineLogSub.unsubscribe();
            this.sendOnlineLogSub = null;
          }
    }

}
